<!--
 * @Description: In User Settings Edit
 * @Author: your name
 * @Date: 2019-09-10 09:43:36
 * @LastEditTime: 2019-09-29 14:49:19
 * @LastEditors: Please set LastEditors
 -->
<template>
    <div class="refware">
        <div class="box">
            <p class="box2_tit">
                <span @click="dianji_fanhui">客户管理</span>
                <i class="el-icon-arrow-right"></i>
                <span>冷冻仓</span>
            </p>
            <div class="box2_title">
                <div class="box2_title_box">
                    <ul>
                        <li v-for="(i,index) in biaotou3_list" :key="index" @click="dianji_fenpei(i)">{{i}}</li>
                    </ul>
                    <div>
                        <!-- <div class="shenpi_xuanze_rqi">
                            <el-select v-model="lengdong_sj_zhi" clearable @change="shijian_xuanze" placeholder="请选择">
                                <el-option
                                v-for="item in lengdong_sj_list"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value">
                                </el-option>
                            </el-select>
                        </div>
                        <div class="min_rqi_qujian">
                            <el-date-picker
                                v-model="lengdong_cang_time"
                                type="daterange"
                                range-separator="至"
                                start-placeholder="开始日期"
                                end-placeholder="结束日期"
                                @change="rqi_xuanze"
                                >
                            </el-date-picker>
                        </div> -->
                        <i class="el-icon-search"></i>
                        <input type="text" v-model="sousuo_text" @keyup.enter="sousuo" placeholder="搜索">
                        <i @click="sousuo_text='',sousuo()" class="el-icon-circle-close"></i>
                    </div>
                </div>
            </div>
            <div class="xiaoshou_table">
                <el-table
                    ref="multipleTable"
                    :data="tableData3"
                    :cell-style="liebiao"
                    @row-click='hangdian'
                    :header-cell-style="biaotou"
                    height="6.5rem"
                    tooltip-effect="dark"
                    style="width: 100%"
                    @selection-change="xuanzhong">
                    <el-table-column
                        type="selection"
                        width="77">
                    </el-table-column>
                    <el-table-column v-for="(i,index) in biao3_tit" :key="index"
                        :prop="i.con"
                        :label="i.name"
                        >
                        <template slot-scope="props">
                            <span v-if="i.con!='sj_time'">{{props.row[i.con]}}</span>
                            <span v-if="props.row[i.con]==undefined||props.row[i.con].length==0">—</span>
                            <div v-if="i.con=='sj_time'" >
                                <p :class="props.row[i.con]==0?'green':(props.row[i.con]==1?'yellow':'red')"></p>
                                {{props.row.time}}
                            </div>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
            <el-pagination
                @size-change="dianji_tiaoshu"
                @current-change="dianji_yeshu"
                :current-page="dangqian_yeshu"
                class="fenye_qi"
                layout="total,sizes, prev, pager, next, jumper"
                :page-sizes="[10, 20, 30, 40,50,60,70,80,90,100]"
                :page-size='tiaoshu'
                :total="dangqian_zongshu">
            </el-pagination>
        </div>
        <div class="beijing" v-if="xianshi_shezhi" @click="xianshi_shezhi=false">
            <div class="beijing_box" @click.stop>
                <p class="beijing_box_tit">显示设置</p>
                <div class="beijing_box_con">
                    <div>
                        <p class="beijing_box_con_tit">选择：</p>
                        <p class="beijing_box_con_quanbu" @click="dianji_xuanze_quanbu"><img :src="xianshi_quanbu?require('../../assets/okblue.png'):require('../../assets/noccc.png')">全部</p>
                        <ul class="renyuan_xuanze_list">
                            <li class="renyuan_xuanze_list_li" v-for="(i,index) in zhanshi_list" :key="index" @click="xuanze_danxuan(i)">
                                <img class="xuanxiang_kuang" :src="i.zhi?require('../../assets/okblue.png'):require('../../assets/noccc.png')">{{i.name}}
                            </li>
                        </ul>
                    </div>
                    <div>
                        <p class="beijing_box_con_tit">显示项：</p>
                        <ul class="renyuan_xuanze_list">
                            <li class="renyuan_xuanze_list_li" v-for='(i,index) in xianshi_xiang_list' :key="index">
                                {{i.name}}<img class="shanchu_xianshi" @click="dianji_shanchu_xianshi(i,index)" src="../../assets/guanbi_zhu.png">
                            </li>
                        </ul>
                    </div>
                </div>
                <p class="beijing_box_foot">
                    <span @click="dianji_lie_queding">确定</span>
                    <span @click="xianshi_shezhi=false">取消</span>
                </p>
            </div>
        </div>
        <div class="beijing" @click='youce_xiangqing=false' v-if="youce_xiangqing">
            <div class="youce_box" @click.stop >
                <div class="yanjing">
                    <table>
                        <tr>
                            <td>成交时间</td>
                            <td>成交金额({{wenzi_jine_zhuanhua(right_zhanshi.chengjiao_jine)}})</td>
                            <td>冷冻时长</td>
                        </tr>
                        <tr>
                            <td>{{right_zhanshi.chengjiao_sj}}</td>
                            <td>{{jine_zhuanhua(right_zhanshi.chengjiao_jine)}}</td>
                            <td>{{right_zhanshi.lengdong_sc}}
                                <span :class="right_zhanshi.color==0?'green':(right_zhanshi.color==1?'yellow':'red')"></span>
                            </td>
                        </tr>
                    </table>
                    <img src="../../assets/me/eye.png" @click="dakai_danju(xiangxi_xinxi)" alt="">
                </div>
                <div class="youce_xiangxi_xinxi" v-for='(item,idx) in xiangxi_xinxi' :key="idx">
                    <!-- {{idx+1}}、 -->
                    <p class="xiangxi_tit" @click="xiangqing_zhankai=!xiangqing_zhankai">详细信息<i :class="xiangqing_zhankai?'el-icon-caret-bottom':'el-icon-caret-left'"></i></p>
                    <transition name="el-zoom-in-top">
                        <div v-show="xiangqing_zhankai" class="transition-box">
                            <!-- {{xiangxi_xinxi}} -->
                            <p v-if="item.cust_name!==undefined">客户名称：{{item.cust_name}}</p>
                            <p v-if="item.file_no!==undefined">归档号：{{item.file_no}}</p>
                            <p v-if='item.staff_name!==undefined'>负责人：{{item.staff_name}}</p>
                            <p v-if="item.dept_name!==undefined">部门：{{item.dept_name}}</p>
                            <div v-if="xiangqing.flow_id=='7TvJHPyovM'">
                                <ul>
                                    <li v-for='(i,index) in item.contract_detail' :key="index">
                                        <p>决策人({{index+1}})</p>
                                        <p v-if="i.name!==undefined">姓名：{{i.name}}</p>
                                        <p v-if="i.sex!==undefined">性别：{{i.sex}}</p>
                                        <p v-if="i.dept!==undefined">部门：{{i.dept}}</p>
                                        <p v-if="i.post!==undefined">职务：{{i.post}}</p>
                                        <p v-if="i.fixed_phone!==undefined">座机：{{i.fixed_phone}}</p>
                                        <p v-if="i.mobile!==undefined">手机：{{i.mobile}}</p>
                                        <p v-if="i.birthday!==undefined">生日：{{i.birthday}}</p>
                                        <p v-if="i.interest!==undefined">爱好：{{i.interest}}</p>
                                        <p v-if="i.qq!==undefined">QQ：{{i.qq}}</p>
                                        <p v-if="i.wechat!==undefined">微信：{{i.wechat}}</p>
                                        <p v-if="i.email!==undefined">邮箱：{{i.email}}</p>
                                        <p v-if="i.card!==undefined">名片：<img @click="tupian_dianji(i.card)" class="tupian_kuang" :src="i.card" alt=""></p>
                                    </li>
                                </ul>
                                <p v-if="item.province!==undefined">省份：{{item.province}}</p>
                                <p v-if="item.city!==undefined">城市：{{item.city}}</p>
                                <p v-if="item.industry!==undefined">行业：{{item.industry}}</p>
                                <p v-if="item.source!==undefined">客户来源：{{item.source}}</p>
                                <p v-if="item.level!==undefined">客户等级：{{item.level}}</p>
                                <p v-if="item.cat_name!==undefined">客户标签：{{item.cat_name}}</p>
                                <p v-if="item.biz_addr!==undefined">经营地址：{{item.biz_addr}}</p>
                                <p v-if="item.rel_p_com!==undefined">母公司名称：{{item.rel_p_com}}</p>
                                <p v-if="item.register_addr!==undefined">注册地址：{{item.register_addr}}</p>
                                <p v-if="item.legal_name!==undefined">法定代表人：{{item.legal_name}}</p>
                                <p v-if="item.uscc!==undefined">营业执照号：{{item.uscc}}</p>
                                <p v-if="item.register_capital!==undefined">注册资本：{{item.register_capital}}</p>
                                <p v-if="item.register_date!==undefined">成立日期：{{item.register_date}}</p>
                                <p v-if="item.biz_term!==undefined">营业期限：{{item.biz_term}}</p>
                                <p v-if="item.biz_scope!==undefined">营业范围：{{item.biz_scope}}</p>
                                <p v-if="item.biz_lic_pic!==undefined">营业执照：<img @click="tupian_dianji(item.biz_lic_pic)" class="tupian_kuang" :src="item.biz_lic_pic" alt=""></p>
                            </div>
                            <ul>
								<li v-for="(i,index) in item.zidingyi" :key="index">
									<!-- <p v-if="i.value.length!=0">{{i.name}}：{{i.value}}</p> -->
									<div v-if="i.type=='ddattachment'">
										<p v-if="i.fujian_list!=undefined&&i.fujian_list.length!=0">附件：</p>
										<ul>
											<li class="keyi_dianji" v-for="(it,inde) in i.fujian_list" :key="inde">
												<el-tooltip class="item" effect="dark" content="点击下载查看" placement="top">
													<p class="fujian_xiazai_kuang" @click="dianji_xiazai_fujian(it)">
														<i class="el-icon-paperclip" style="margin-right:0.1rem;"></i>
														{{it.name}}
													</p>
												</el-tooltip>
											</li>
										</ul>
									</div>
									<!-- 关联审批单 -->
									<div class="rqi_kuang_xiangqiang" v-if="i.type=='assappform'">
										<p>{{i.name}}：</p>
										<ul>
											<li class="keyi_dianji" @click="biaodan_xiangqing(it)" v-for="(it,inde) in i.liebiao" :key="inde">
												<p class="guanlian_kuang">
													<i class="el-icon-connection" style="margin-right:0.1rem;"></i>
													{{it.flow_desc}}:{{it.start_time}}
												</p>
											</li>
										</ul>
										<BiaodanXiangqing ref='biaodan_xiangqing_zhanshi'></BiaodanXiangqing>
									</div>
									<!-- <div v-if="i.type=='province'" @click="ceshi_diqu2(i)" class="rqi_kuang_xiangqiang">
										{{i.name}}：
										<v-distpicker @selected="onSelected2" :province='i.value_id' :city='i.value_ids' :area='i.value_idss'></v-distpicker>
									</div> -->
									<div v-if="i.type=='tablefield'">
										<div v-for="(it,inde) in i.mingxi_list" :key="inde">
											{{i.name+'('+(inde+1)+')'}}：
											<div v-for="(ite,ind) in it.mingxi_list" :key="ind">
												<p v-if="ite.type=='ddphotofield'&&ite.img_url!=undefined&&ite.img_url.length!=0">{{ite.name}}：<img @click="tupian_dianji(ite.img_url)" class="tupian_kuang" :src="ite.img_url" alt=""></p>
												<p v-if="ite.type!='ddattachment'&&ite.type!='ddphotofield'&&ite.type!='assappform'&&ite.type!='product'&&ite.value!=undefined&&ite.value.length!=0">
													{{ite.name}}：{{ite.value}}
												</p>
												<div v-if="ite.type=='product'&&ite.value!=undefined&&ite.value.length!=0">
													<p>{{ite.name}}：{{ite.value}}</p>
													<p>产品编号：{{ite.liebiao[0].prod_code}}</p>
													<p>产品类型：{{ite.liebiao[0].cat_name}}</p>
													<p>规格：{{ite.liebiao[0].spec}}</p>
													<p>型号：{{ite.liebiao[0].type}}</p>
													<p>单位：{{ite.liebiao[0].unit}}</p>
												</div>
											</div>
										</div>
									</div>
									<p v-if="i.type=='ddphotofield'&&i.img_url!=undefined&&i.img_url.length!=0">{{i.name}}：<img @click="tupian_dianji(i.img_url)" class="tupian_kuang" :src="i.img_url" alt=""></p>
									<p v-if="i.type!='ddattachment'&&i.type!='ddphotofield'&&i.type!='assappform'&&i.type!='tablefield'&&i.type!='product'&&i.value!=undefined&&i.value.length!=0">
										{{i.name}}：{{i.value}}
									</p>
									<div v-if="i.type=='product'&&i.value!=undefined&&i.value.length!=0">
										<p>{{i.name}}：{{i.value}}</p>
										<p>产品编号：{{i.liebiao[0].prod_code}}</p>
										<p>产品类型：{{i.liebiao[0].cat_name}}</p>
										<p>规格：{{i.liebiao[0].spec}}</p>
										<p>型号：{{i.liebiao[0].type}}</p>
										<p>单位：{{i.liebiao[0].unit}}</p>
									</div>
								</li>
							</ul>
                        </div>
                    </transition>
                </div>
                <div style="margin-top:0.3rem;" class="youce_xiangxi_xinxi">
                    <p class="xiangxi_tit" @click="xiangqing_kehu_zhankai=!xiangqing_kehu_zhankai">客户操作日志<i :class="xiangqing_kehu_zhankai?'el-icon-caret-bottom':'el-icon-caret-left'"></i></p>
                    <transition name="el-zoom-in-top">
                        <div v-show="xiangqing_kehu_zhankai" class="transition-box">
                            <ul>
                                <li v-for="(i,index) in zhanshi_lishi" :key="index">
                                    <p v-if="i.type=='1'">{{index+1}}、{{i.operate_time}}-{{i.cust_name}}被系统分配给{{i.dept_name}}{{i.staff_name}}；</p>
                                    <p v-if="i.type=='2'">{{index+1}}、{{i.operate_time}}-{{i.cust_name}}被{{i.dept_name}}{{i.staff_name}}退回公海池；</p>
                                    <p v-if="i.type=='3'">{{index+1}}、{{i.operate_time}}-{{i.cust_name}}被{{i.operate_dept_name}}{{i.operate_user_name}}从{{i.dept_name}}冷冻仓激活到公海池；</p>
                                    <p v-if="i.type=='4'">{{index+1}}、{{i.operate_time}}-{{i.dept_name}}{{i.staff_name}}接收{{i.operate_dept_name}}{{i.operate_user_name}}指派的{{i.cust_name}}；</p>
                                    <p v-if="i.type=='5'">{{index+1}}、{{i.operate_time}}-{{i.dept_name}}{{i.staff_name}}拒绝{{i.operate_dept_name}}{{i.operate_user_name}}指派的{{i.cust_name}}；</p>
                                    <p v-if="i.type=='6'">{{index+1}}、{{i.operate_time}}-{{i.cust_name}}被{{i.operate_dept_name}}{{i.operate_user_name}}录入公海池；</p>
                                    <p v-if="i.type=='7'">{{index+1}}、{{i.operate_time}}-{{i.cust_name}}被{{i.operate_dept_name}}{{i.operate_user_name}}指派给{{i.dept_name}}{{i.staff_name}}；</p>
                                    <p v-if="i.type=='8'">{{index+1}}、{{i.operate_time}}-{{i.cust_name}}被{{i.operate_dept_name}}{{i.operate_user_name}}录入已分配；</p>
                                    <p v-if="i.type=='9'">{{index+1}}、{{i.operate_time}}-{{i.cust_name}}被{{i.operate_dept_name}}{{i.operate_user_name}}修改；</p>
                                    <p v-if="i.type=='10'">{{index+1}}、{{i.operate_time}}-{{i.cust_name}}被系统录入</p>
                                    <p v-if="i.type=='11'">{{index+1}}、{{i.operate_time}}-{{i.dept_name}}{{i.cust_name}}被系统自动退回</p>
                                    <p v-if="i.type=='12'">{{index+1}}、{{i.operate_time}}-{{i.dept_name}}{{i.cust_name}}被系统自动从公海池退回冷冻仓</p>
                                    <!-- <p v-if="i.type=='1'">{{index+1}}、{{i.operate_time}}-{{i.cust_name}}被分配给{{i.dept_name}}{{i.staff_name}}；</p>
                                    <p v-if="i.type=='2'">{{index+1}}、{{i.operate_time}}-{{i.cust_name}}被{{i.dept_name}}{{i.operate_user_name}}退回公海池；</p>
                                    <p v-if="i.type=='3'">{{index+1}}、{{i.operate_time}}-{{i.cust_name}}被{{i.operate_user_name}}从冷冻仓激活；</p>
                                    <p v-if="i.type=='4'">{{index+1}}、{{i.operate_time}}-{{i.cust_name}}被{{i.operate_user_name}}指派给{{i.dept_name}}{{i.staff_name}}，{{i.accept_time}}{{i.staff_name}}已接收；</p>
                                    <p v-if="i.type=='5'">{{index+1}}、{{i.operate_time}}-{{i.cust_name}}被{{i.operate_user_name}}指派给{{i.dept_name}}{{i.staff_name}}，{{i.accept_time}}{{i.staff_name}}已拒绝；</p>
                                    <p v-if="i.type=='7'">{{index+1}}、{{i.operate_time}}-{{i.cust_name}}被{{i.operate_user_name}}指派给{{i.dept_name}}{{i.staff_name}}，待{{i.staff_name}}接受；</p>
                                    <p v-if="i.type=='6'&&i.operate_user_id=='sys'">{{index+1}}、{{i.operate_time}}-{{i.cust_name}}被系统录入公海池；</p>
                                    <p v-if="i.type=='6'&&i.operate_user_id!='sys'">{{index+1}}、{{i.operate_time}}-{{i.cust_name}}{{i.dept_name}}{{i.operate_user_name}}被系统录入公海池；</p> -->
                                </li>
                            </ul>
                        </div>
                    </transition>
                </div>
                <div style="margin-top:0.3rem;" class="youce_xiangxi_xinxia">
                    <p class="xiangxi_tit" @click="xiangqing_kehu_fujian=!xiangqing_kehu_fujian">附件下载<i :class="xiangqing_kehu_fujian?'el-icon-caret-bottom':'el-icon-caret-left'"></i></p>
                    <transition name="el-zoom-in-top">
                        <div v-show="xiangqing_kehu_fujian" class="transition-box">
                            <p class="transition-box_tit">
                                选择归档号：
                            </p>
                            <p class='transition-box_tit_file'>
                                <span v-for="(i,index) in fujian_list" @click="dianji_guidang_hao(index)" :key="index"><img :src="zhanshi_guidang_hao==index?require('../../assets/me/xuan_zi.png'):require('../../assets/me/weixuan_zi.png')" alt="">{{i.file_no}}</span>
                            </p>
                            <div class="fujian_con" v-if="fujian_list.length!=0&&fujian_list[zhanshi_guidang_hao].con_annex!=undefined">
                                <p class="fujian_con_tit">合同附件:</p>
                                <ul>
                                    <li v-for="(ita,ind) in fujian_list[zhanshi_guidang_hao].con_annex" :key="ind">
                                        <p>
                                            <img @click="ita.zhi=!ita.zhi" :src="ita.zhi?require('../../assets/okblue.png'):require('../../assets/noccc.png')" alt="">
                                            {{ita.annex_name}}
                                        </p>
                                        <span @click="fujian_xiazai(ita)">下载</span>        
                                    </li>
                                </ul>
                            </div>
                            <div class="fujian_con" v-if="fujian_list.length!=0&&fujian_list[zhanshi_guidang_hao].inv_annex!=undefined">
                                <p class="fujian_con_tit">发票附件:</p>
                                <ul>
                                    <li v-for="(ita,ind) in fujian_list[zhanshi_guidang_hao].inv_annex" :key="ind">
                                        <p>
                                            <img @click="ita.zhi=!ita.zhi" :src="ita.zhi?require('../../assets/okblue.png'):require('../../assets/noccc.png')" alt="">
                                            {{ita.annex_name}}
                                        </p>
                                        <span @click="fujian_xiazai(ita)">下载</span>        
                                    </li>
                                </ul>
                            </div>
                            <div class="fujian_con" v-if="fujian_list.length!=0&&fujian_list[zhanshi_guidang_hao].rec_annex!=undefined">
                                <p class="fujian_con_tit">回款附件:</p>
                                <ul>
                                    <li v-for="(ita,ind) in fujian_list[zhanshi_guidang_hao].rec_annex" :key="ind">
                                        <p>
                                            <img @click="ita.zhi=!ita.zhi" :src="ita.zhi?require('../../assets/okblue.png'):require('../../assets/noccc.png')" alt="">
                                            {{ita.annex_name}}
                                        </p>
                                        <span @click="fujian_xiazai(ita)">下载</span>        
                                    </li>
                                </ul>
                            </div>
                            <div class="fujian_con" v-if="fujian_list.length!=0&&fujian_list[zhanshi_guidang_hao].deliver_annex!=undefined">
                                <p class="fujian_con_tit">交付附件:</p>
                                <ul>
                                    <li v-for="(ita,ind) in fujian_list[zhanshi_guidang_hao].deliver_annex" :key="ind">
                                        <p>
                                            <img @click="ita.zhi=!ita.zhi" :src="ita.zhi?require('../../assets/okblue.png'):require('../../assets/noccc.png')" alt="">
                                            {{ita.annex_name}}
                                        </p>
                                        <span @click="fujian_xiazai(ita)">下载</span>        
                                    </li>
                                </ul>
                            </div>
                            <p class="fujian_foot" v-if="fujian_list.length!=0&&(fujian_list[zhanshi_guidang_hao].deliver_annex!=undefined||fujian_list[zhanshi_guidang_hao].con_annex!=undefined||fujian_list[zhanshi_guidang_hao].rec_annex!=undefined||fujian_list[zhanshi_guidang_hao].inv_annex!=undefined)">
                                <span @click="dianji_quanxuan_fujian">
                                    <img :src="fujian_quanxuan?require('../../assets/okblue.png'):require('../../assets/noccc.png')" alt="">
                                    全选
                                </span>
                                <span @click="dianji_piliang_xiazi">批量下载</span>
                            </p>
                            <p class="no_fujian" v-if="fujian_list.length==0||(fujian_list[zhanshi_guidang_hao].deliver_annex==undefined&&fujian_list[zhanshi_guidang_hao].con_annex==undefined&&fujian_list[zhanshi_guidang_hao].rec_annex==undefined&&fujian_list[zhanshi_guidang_hao].inv_annex==undefined)">该归档号下没有可以下载的附件</p>
                        </div>
                    </transition>
                </div>
            </div>
        </div>
        <!-- <el-dialog title="选择部门" :center="true" :show-close='false' append-to-body :visible.sync="bumen">
            <div class="tiao_bumen">
                <div class="xuanze">
                    <p class="tit">选择：</p>
                    <div class="nei_box">
                        <el-tree
                            :data="bumen_tree"
                            show-checkbox
                            default-expand-all
                            ref="tree_tre"
                            node-key="id"
                            highlight-current
                            :props="tree_guize"
                            @check-change="handleClick"
                            style="background: #E7E8EB"
                            >
                        </el-tree>
                    </div>
                </div>
            </div>
            <div class="foote">
                <p class="queding" @click="bumen_sure">确定</p>
                <p class="quxiao" @click="bumen=false">取消</p>
            </div>
        </el-dialog> -->
		<yanjing ref="eye"></yanjing>
		<tupian ref="bigphote"></tupian>
    </div>
</template>

<script>
import yanjing from '../../page/pagea/yanjing'
import tupian from '../../components/bigphote/bigphote'
import { query_user_info, customer_annex_download, query_customer_else_info, query_cust_allot_log_list, query_customer_allot_rule_detail, query_all_module_read_power_list, query_ent_dept_list_aim, query_customer_detail, query_customer_mng_log_list, activate_customer, query_customer_list_cold } from '../../api/api.js'
export default {
  name: 'refware',
  data () {
    return {
      biaotou3_list: [],
      lengdong_sj_zhi: '',
            
      lengdong_sj_list: [
        {
          label: '冷冻时间',
          value: '1'
        },
        {
          label: '成交时间',
          value: '2'
        }
      ],
      lengdong_cang_time: '',
      biao3_tit: [
        {
          name: '客户',
          con: 'cust_name'
        },
        {
          name: '客户等级',
          con: 'level'
        },
        {
          name: '客户标签',
          con: 'tag_name'
        },
        {
          name: '部门',
          con: 'dept_name'
        },
        {
          name: '决策人',
          con: 'rel_name'
        },
        {
          name: '客户来源',
          con: 'source'
        },
        {
          name: '行业',
          con: 'industry'
        },
        {
          name: '省份',
          con: 'province'
        }
      ],
      // {
      //         name:'成交时间',
      //         con:'date_trans'
      //     },
      //     {
      //         name:'成交金额',
      //         con:'chengjiao_jine'
      //     },
      //     {
      //         name:'冷冻时长',
      //         con:'sj_time'
      //     },
      tableData3: [],
      xianshi_quanbu: false,
      zhanshi_list: [
        {
          name: '客户',
          con: 'cust_name',
          zhi: false
        },
        {
          name: '归档号',
          con: 'file_no',
          zhi: false
        },
        {
          name: '部门',
          con: 'dept_name',
          zhi: false
        },
        {
          name: '决策人',
          con: 'rel_name',
          zhi: false
        },
        // {
        //     name:'成交时间',
        //     con:'date_trans',
        //     zhi:false,
        // },
        // {
        //     name:'成交金额',
        //     con:'chengjiao_jine',
        //     zhi:false,
        // },
        // {
        //     name:'冷冻时长',
        //     con:'sj_time'
        // },
        {
          name: '省份',
          con: 'province',
          zhi: false
        },
        {
          name: '城市',
          con: 'city',
          zhi: false
        },
        {
          name: '行业',
          con: 'industry',
          zhi: false
        },
        {
          name: '客户来源',
          con: 'source',
          zhi: false
        },
        {
          name: '客户等级',
          con: 'level',
          zhi: false
        },
        {
          name: '客户来源',
          con: 'source',
          zhi: false
        },
        {
          name: '客户标签',
          con: 'tag_name',
          zhi: false
        },
        {
          name: '经营地址',
          con: 'biz_addr',
          zhi: false
        },
        {
          name: '母公司名称',
          con: 'rel_p_com',
          zhi: false
        },
        {
          name: '注册地址',
          con: 'register_addr',
          zhi: false
        },
        {
          name: '法定代表人',
          con: 'legal_name',
          zhi: false
        },
        {
          name: '营业执照号',
          con: 'uscc',
          zhi: false
        },
        {
          name: '注册资本',
          con: 'register_capital',
          zhi: false
        },
        {
          name: '成立日期',
          con: 'register_date',
          zhi: false
        },
        {
          name: '营业期限',
          con: 'biz_term',
          zhi: false
        },
        {
          name: '营业范围',
          con: 'biz_scope',
          zhi: false
        }
      ],
      xianshi_shezhi: false,
      xuan_zhong: [],
      sousuo_text: '',
      youce_xiangqing: false,
      xiangxi_xinxi: [],
      xiangqing_zhankai: false,
      xiangqing: {
        flow_id: '7TvJHPyovM'
      },
      xiangqing_kehu_zhankai: false,
      zhanshi_lishi: [],
      bumen: false,
      bumen_tree: [],
      tree_guize: {
        children: 'children',
        label: 'label'
      },
      quanbu_kejian: false,
      dangqian_yeshu: sessionStorage.getItem('cbr_lengdong_cang') != undefined && sessionStorage.getItem('cbr_lengdong_cang').length != 0 ? sessionStorage.getItem('cbr_lengdong_cang') : 1,
      dangqian_zongshu: 0,
      tiaoshu: 10,
      xiangqing_kehu_fujian: false,
      zhanshi_guidang_hao: '0',
      fujian_quanxuan: false,
      fujian_list: [],
      right_zhanshi: {
        chengjiao_sj: '',
        chengjiao_jine: '',
        lengdong_sc: '',
        color: ''
      },
      xianshi_xiang_list: []
    }
  },
  mounted () {
  },
  components: { yanjing, tupian },
  created () {
    sessionStorage.removeItem('mlbb_one_shezhi')
    sessionStorage.removeItem('mlbb_four_shezhi')
    sessionStorage.removeItem('mlbb_shenpi_zhengyi')
    sessionStorage.removeItem('mlbb_biaodan_sheji')
    sessionStorage.removeItem('mlbb_liucheng_shezhi')
    sessionStorage.removeItem('mlbb_liucheng_shezhi_flowPermission')
    sessionStorage.removeItem('mlbb_liucheng_shezhi_tableId')
    sessionStorage.removeItem('mlbb_liucheng_shezhi_list')
    this.jichu()
  },
  watch: {
    dangqian_yeshu () {
      sessionStorage.setItem('cbr_lengdong_cang', JSON.stringify(this.dangqian_yeshu))
      this.liebiao_jiekou()
    },
    tiaoshu () {
      this.liebiao_jiekou()
    },
    fujian_list: {
      handler (newValue, oldValue) {
        this.panduan_fujian_quanxuan()
      },
      deep: true
    },
    youce_xiangqing () {
      if (!this.youce_xiangqing) {
        this.zhanshi_lishi = []
        this.xiangqing_kehu_zhankai = false
        this.right_zhanshi = {
          chengjiao_sj: '',
          chengjiao_jine: '',
          lengdong_sc: '',
          color: ''
        }
      }
    }
  },
  methods: {
    jichu () {
      // let quanxian=''
      // if(this.$func.quanxian()==='0'){
      //     quanxian='0'
      // }else{
      //     let data=this.$func.quanxian()
      //     quanxian=data.map((item,index)=>{if(item.module_id==2){return item.read_only}})
      // }
      // if(quanxian=='1'){
      // this.biaotou3_list=['显示设置'];
      // }else if(quanxian=='2'){
      query_all_module_read_power_list({
        data: {
          ent_id: this.$ent_id(),
          user_id: this.$jichuxinxi().user_id
        }
      }).then(res => {
        console.log(res)
        if (res.data.code == 200) {
          const date = JSON.parse(res.data.body.data)
          console.log(date)
          if (date.length != 0) {
            this.quanbu_kejian = true
            this.biaotou3_list = ['显示设置']
          } else {
            this.quanbu_kejian = false
            this.biaotou3_list = ['激活', '显示设置']
          }
        } else if (res.data.code == 500) {}
      })
      query_customer_allot_rule_detail({
        data: {
          ent_id: this.$ent_id()
        }
      }).then(rzs => {
        console.log(rzs)
        if (rzs.data.code == 200) {
          if (rzs.data.body != null) {
            const data = JSON.parse(rzs.data.body.data)
            console.log(data)
            const lv = data.stay_time_green.split(',')
            const yellow = data.stay_time_yellow.split(',')
            const lv1 = Number(lv[0])
            const lv2 = Number(lv[1])
            const yellow1 = Number(yellow[0])
            const yellow2 = Number(yellow[1])
            const red = Number(data.stay_time_red)
            this.lv1 = lv1
            this.lv2 = lv2
            this.yellow1 = yellow1
            this.yellow2 = yellow2
            this.red = red
                        
            this.liebiao_jiekou()
          } else {
            this.$message({
              message: '还未分配规则',
              type: 'warning'
            }) 
          }
        } else if (rzs.data.code == 500) {}
      })
    },
    liebiao_jiekou () {
      this.lengdong_sj_zhi = this.lengdong_sj_zhi.length == 0 ? '1' : this.lengdong_sj_zhi
      const date = this.lengdong_cang_time
      let start_time = null
      let end_time = null
      if (date != null && date != undefined && date.length != 0) {
        start_time = date[0].getFullYear() + '-' + this.$func.pa(date[0].getMonth() + 1) + '-' + this.$func.pa(date[0].getDate())
        end_time = date[1].getFullYear() + '-' + this.$func.pa(date[1].getMonth() + 1) + '-' + this.$func.pa(date[1].getDate())
      }
      const obj = {
        ent_id: this.$ent_id(),
        search_str: this.sousuo_text.length != 0 ? this.sousuo_text : null,
        // time_type:this.lengdong_sj_zhi.length!=0?this.lengdong_sj_zhi:null,
        // date_start:start_time,
        // date_end:end_time,
        user_id: this.$jichuxinxi().user_id,
        page_size: this.tiaoshu + '',
        page_index: this.dangqian_yeshu + ''
      }
      query_customer_list_cold({ data: obj }).then(res => {
        console.log(res)
        if (res.data.code == 200) {
          const date = JSON.parse(res.data.body.data)
          console.log(date)
          date.list.map(item => {
            if (Number(item.stay_long) >= Number(this.lv1) && Number(item.stay_long) <= Number(this.lv2)) {
              this.$set(item, 'sj_time', '0')
              this.$set(item, 'time', Number(this.lv1) + '-' + Number(this.lv2) + '天')
            } else if (Number(item.stay_long) > Number(this.yellow1) && Number(item.stay_long) <= Number(this.yellow2)) {
              this.$set(item, 'sj_time', '1')
              this.$set(item, 'time', Number(this.yellow1) + '-' + Number(this.yellow2) + '天')
            } else if (Number(item.stay_long) > Number(this.red)) {
              this.$set(item, 'sj_time', '2')
              this.$set(item, 'time', Number(this.red) + '天以上')
            }
            let chengjiao_jine = ''
            if (item.amt_trans != undefined) {
              const jine_list = item.amt_trans.split(',')
              const jine_zhan = []
              jine_list.forEach(it => {
                jine_zhan.push(this.$qianwei(Number(it).toFixed(2)))
              })
              chengjiao_jine = jine_zhan.join(',')
            }
            this.$set(item, 'chengjiao_jine', chengjiao_jine)
          })
          this.dangqian_zongshu = date.total
          this.tableData3 = date.list
        } else if (res.data.code == 500) {}
      })
    },
    dianji_yeshu (val) {
      this.dangqian_yeshu = val
    },
    dianji_tiaoshu (val) {
      console.log(`每页 ${val} 条`)
      this.tiaoshu = val
      this.dangqian_yeshu = 1
    },
    dianji_fanhui () {
      this.$router.push('/cusman')
    },
    // 已分配里点击事件
    dianji_fenpei (i) {
      console.log(i)
      if (i == '显示设置') {
        this.biao3_tit.forEach(item => {
          this.zhanshi_list.forEach(ite => {
            if (item.con == ite.con) {
              this.xuanze_danxuan(ite)
            }
          })
        })
        this.xianshi_shezhi = true
      } else if (i == '激活') {
        console.log(this.xuan_zhong)
        if (this.xuan_zhong.length != 0) {
          // this.bumen=true
          // query_ent_dept_list_aim({data:{
          //     ent_id:this.$ent_id(),
          //     user_id:this.$jichuxinxi().user_id
          // }}).then(res=>{
          //     console.log(res)
          //     if(res.data.code==10168){
          //         let date=JSON.parse(res.data.body.data)
          //         console.log(date)
          //         this.bumen_tree=date
          //     }else if(res.data.code==10169){}
          // })
          query_user_info({
            data: {
              ent_id: this.$ent_id(),
              user_id: this.$jichuxinxi().user_id
            }
          }).then(ras => {
            console.log(ras)
            if (ras.data.code == 200) {
              const date = ras.data.body
              activate_customer({
                data: {
                  ent_id: this.$ent_id(),
                  user_id: this.$jichuxinxi().user_id,
                  operate_dept_id: date.dept_ids != undefined && date.dept_ids != null && date.dept_ids.length != 0 ? date.dept_ids : null,
                  // dept_id:this.xuan_zhong[0].dept_id,
                  list: this.xuan_zhong.map(item => {
                    return {
                      cust_id: item.cust_id,
                      dept_id: item.dept_id
                    }
                  })
                }
              }).then(res => {
                console.log(res)
                if (res.data.code == 200) {
                  this.$message({
                    message: '操作成功',
                    type: 'success'
                  }) 
                  this.jichu()
                } else if (res.data.code == 500) {}
              })
            } else if (ras.data.code == 500) {}
          })
        } else {
          this.$message({
            message: '请选择客户进行激活',
            type: 'warning'
          }) 
        }
      }
    },
    // 表头颜色
    biaotou (row) { // 根据报警级别显示颜色
      return 'text-align:center;color:#1A2533;'
    },
    // color:#4C4A4D;font-size:0.12rem;
    liebiao (row) {
      return 'text-align:center;color:#1A2533;'
    },
    xuanzhong (val) {
      this.xuan_zhong = val
    },
    // 点击选择全部
    dianji_xuanze_quanbu () {
      this.xianshi_quanbu = !this.xianshi_quanbu
      if (this.xianshi_quanbu) {
        this.zhanshi_list.map((i, index) => { i.zhi = true })
        this.xianshi_xiang_list = []
        for (let i = 0; i < this.zhanshi_list.length; i++) {
          this.xianshi_xiang_list.push(this.zhanshi_list[i])
        }
      } else {
        this.zhanshi_list.map((i, index) => { i.zhi = false })
        this.xianshi_xiang_list = []
      }
    },
    xuanze_danxuan (i) {
      i.zhi = !i.zhi
      if (i.zhi) {
        this.xianshi_xiang_list.push(i)
      } else {
        for (let a = 0; a < this.xianshi_xiang_list.length; a++) {
          if (this.xianshi_xiang_list[a].con == i.con) {
            this.xianshi_xiang_list.splice(a, 1)
          }
        }
      }
    },
    dianji_shanchu_xianshi (i, index) {
      this.zhanshi_list.map((item, idx) => {
        if (item.con == i.con) {
          item.zhi = false
        }
      })
      this.xianshi_xiang_list.splice(index, 1)
    },
    dianji_lie_queding () {
      this.biao3_tit = this.xianshi_xiang_list
      this.xianshi_xiang_list = []
      this.xianshi_shezhi = false
    },
    sousuo () {
      const liexing = this.lengdong_sj_zhi.length != 0 ? this.lengdong_sj_zhi : null
      const date = this.lengdong_cang_time
      let start_time = null
      let end_time = null
      if (date != null && date != undefined && date.length != 0) {
        start_time = date[0].getFullYear() + '-' + this.$func.pa(date[0].getMonth() + 1) + '-' + this.$func.pa(date[0].getDate())
        end_time = date[1].getFullYear() + '-' + this.$func.pa(date[1].getMonth() + 1) + '-' + this.$func.pa(date[1].getDate())
      }
      const obj = {
        ent_id: this.$ent_id(),
        search_str: this.sousuo_text.length != 0 ? this.sousuo_text : null,
        // time_type:liexing,
        // date_start:start_time,
        // date_end:end_time,
        user_id: this.$jichuxinxi().user_id,
        page_size: this.tiaoshu + '',
        page_index: '1'
      }
      this.dangqian_yeshu = 1
      this.sousuo_jiekou(obj)
    },
    rqi_xuanze () {
      this.lengdong_sj_zhi = this.lengdong_sj_zhi.length == 0 ? '1' : this.lengdong_sj_zhi
      const date = this.lengdong_cang_time
      let start_time = null
      let end_time = null
      if (date != null && date != undefined && date.length != 0) {
        start_time = date[0].getFullYear() + '-' + this.$func.pa(date[0].getMonth() + 1) + '-' + this.$func.pa(date[0].getDate())
        end_time = date[1].getFullYear() + '-' + this.$func.pa(date[1].getMonth() + 1) + '-' + this.$func.pa(date[1].getDate())
      }
      const obj = {
        ent_id: this.$ent_id(),
        search_str: this.sousuo_text.length != 0 ? this.sousuo_text : null,
        // time_type:this.lengdong_sj_zhi.length!=0?this.lengdong_sj_zhi:null,
        // date_start:start_time,
        // date_end:end_time,
        user_id: this.$jichuxinxi().user_id,
        page_size: this.tiaoshu + '',
        page_index: this.dangqian_yeshu + ''
      }
      this.sousuo_jiekou(obj)
    },
    sousuo_jiekou (obj) {
      query_customer_list_cold({ data: obj }).then(res => {
        console.log(res)
        if (res.data.code == 200) {
          const date = JSON.parse(res.data.body.data)
          console.log(date)
          date.list.map(item => {
            if (Number(item.stay_long) >= Number(this.lv1) && Number(item.stay_long) <= Number(this.lv2)) {
              this.$set(item, 'sj_time', '0')
              this.$set(item, 'time', Number(this.lv1) + '-' + Number(this.lv2) + '天')
            } else if (Number(item.stay_long) > Number(this.yellow1) && Number(item.stay_long) <= Number(this.yellow2)) {
              this.$set(item, 'sj_time', '1')
              this.$set(item, 'time', Number(this.yellow1) + '-' + Number(this.yellow2) + '天')
            } else if (Number(item.stay_long) > Number(this.red)) {
              this.$set(item, 'sj_time', '2')
              this.$set(item, 'time', Number(this.red) + '天以上')
            }
            let chengjiao_jine = ''
            if (item.amt_trans != undefined) {
              const jine_list = item.amt_trans.split(',')
              const jine_zhan = []
              jine_list.forEach(it => {
                jine_zhan.push(this.$qianwei(Number(it).toFixed(2)))
              })
              chengjiao_jine = jine_zhan.join(',')
            }
            this.$set(item, 'chengjiao_jine', chengjiao_jine)
          })
          this.dangqian_zongshu = date.total
          this.tableData3 = date.list
        } else if (res.data.code == 500) {}
      })
    },
    shijian_xuanze () {
      const date = this.lengdong_cang_time
      let start_time = null
      let end_time = null
      if (date != null && date != undefined && date.length != 0) {
        start_time = date[0].getFullYear() + '-' + this.$func.pa(date[0].getMonth() + 1) + '-' + this.$func.pa(date[0].getDate())
        end_time = date[1].getFullYear() + '-' + this.$func.pa(date[1].getMonth() + 1) + '-' + this.$func.pa(date[1].getDate())
        const obj = {
          ent_id: this.$ent_id(),
          search_str: this.sousuo_text.length != 0 ? this.sousuo_text : null,
          // time_type:this.lengdong_sj_zhi.length!=0?this.lengdong_sj_zhi:null,
          // date_start:start_time,
          // date_end:end_time,
          user_id: this.$jichuxinxi().user_id,
          page_size: this.tiaoshu + '',
          page_index: this.dangqian_yeshu + ''
        }
        this.sousuo_jiekou(obj)
      }
    },
    // 表格行点
    hangdian (val) {
      console.log(val)
      const that = this
      query_customer_detail({
        data: {
          ent_id: this.$ent_id(),
          cust_id: val.cust_id,
          file_no: val.file_no,
          dept_id: val.dept_id
        }
      }).then(rds => {
        console.log(rds)
        if (rds.data.code == 200) {
          const xiangqing = JSON.parse(rds.data.body.data)
          console.log(xiangqing)
          this.xiangxi_xinxi = []
          this.xiangxi_xinxi.push(xiangqing)
          // 附件下载接口
          customer_annex_download({
            data: {
              ent_id: this.$ent_id(),
              cust_id: val.cust_id,
              // dept_id:val.dept_id,
              file_no: xiangqing.file_no,
              staff_id: val.staff_id != undefined ? val.staff_id : null
            }
          }).then(fujian => {
            console.log(fujian)
            if (fujian.data.code == 200) {
              const date = JSON.parse(fujian.data.body.data)
              console.log(date)
              date.forEach(item => {
                if (item.con_annex != undefined) {
                  item.con_annex.forEach(ite => {
                    this.$set(ite, 'zhi', false)
                  })
                }
                if (item.inv_annex != undefined) {
                  item.inv_annex.forEach(ite => {
                    this.$set(ite, 'zhi', false)
                  })
                }
                if (item.rec_annex != undefined) {
                  item.rec_annex.forEach(ite => {
                    this.$set(ite, 'zhi', false)
                  })
                }
                if (item.deliver_annex != undefined) {
                  item.deliver_annex.forEach(ite => {
                    this.$set(ite, 'zhi', false)
                  })
                }
              })
              for (let i = 0; i < date.length; i++) {
                if (date[i].file_no == undefined) {
                  date.splice(i, 1)
                  i = i - 1
                }
              }
              setTimeout(() => {
                this.fujian_list = date
              }, 0)
            } else if (fujian.data.code == 500) {}
          })
        } else if (rds.data.code == 500) {}
      })
      query_customer_mng_log_list({
        data: {
          ent_id: this.$ent_id(),
          cust_id: val.cust_id
        }
      }).then(res => {
        console.log(res)
        if (res.data.code == 200) {
          const date = JSON.parse(res.data.body.data)
          console.log(date)
          this.zhanshi_lishi = date != undefined && date != null && date.length != 0 ? date : []
          // let list=[]
          // date.forEach(ite=>{
          //     if(ite.type!=4&&ite.type!=5){
          //         list.push(ite)
          //     }
          // })
          // query_cust_allot_log_list({data:{
          //     ent_id:this.$ent_id(),
          //     // user_id:this.$jichuxinxi().user_id,
          //     cust_id:val.cust_id
          // }}).then(ras=>{
          //     console.log(ras)
          //     if(ras.data.code==200){
          //         let data=JSON.parse(ras.data.body.data)
          //         console.log(data)
          //         data.forEach(item=>{
          //             if(item.accept_status==0){
          //                 this.$set(item,'operate_time',item.allot_time)
          //                 this.$set(item,'type',7)
          //                 this.$set(item,'operate_user_name',item.allot_user_name)
          //                 list.push(item)
          //             }else if(item.accept_status==1){
          //                 this.$set(item,'operate_time',item.allot_time)
          //                 this.$set(item,'type',4)
          //                 this.$set(item,'operate_user_name',item.allot_user_name)
          //                 list.push(item)
          //             }else if(item.accept_status==2){
          //                 this.$set(item,'operate_time',item.allot_time)
          //                 this.$set(item,'type',5)
          //                 this.$set(item,'operate_user_name',item.allot_user_name)
          //                 list.push(item)
          //             }
          //         })
          //         list=this.$func.ReverseRankingDate(list,'operate_time')
          //         console.log(list)
          //         this.zhanshi_lishi=list
          //     }else if(res.data.code==500){}
          // })
        } else if (res.data.code == 500) {}
      })
      query_customer_else_info({
        data: {
          ent_id: this.$ent_id(),
          cust_id: val.cust_id,
          type: '3',
          dept_id: val.dept_id
        }
      }).then(res => {
        console.log(res)
        if (res.data.code == 200) {
          const date = JSON.parse(res.data.body.data)
          console.log(date)
          if (Number(date.stay_long) >= Number(that.lv1) && Number(date.stay_long) <= Number(that.lv2)) {
            that.right_zhanshi.color = '0'
            that.right_zhanshi.lengdong_sc = Number(that.lv1) + '-' + Number(that.lv2) + '天'
          } else if (Number(date.stay_long) > Number(that.yellow1) && Number(date.stay_long) <= Number(that.yellow2)) {
            that.right_zhanshi.color = '1'
            that.right_zhanshi.lengdong_sc = Number(that.yellow1) + '-' + Number(that.yellow2) + '天'
          } else if (Number(date.stay_long) > Number(that.red)) {
            that.right_zhanshi.color = '2'
            that.right_zhanshi.lengdong_sc = Number(that.red) + '天以上'
          }
          that.right_zhanshi.chengjiao_sj = date.date_trans.length != 0 ? date.date_trans : '—'
          that.right_zhanshi.chengjiao_jine = Number(date.amt_trans).toFixed(2)
        } else if (res.data.code == 500) {}
      })
      this.youce_xiangqing = true
    },
    handleClick (data, checked, node) {
      console.log(data)
      if (checked) {
        this.$refs.tree_tre.setCheckedNodes([data])
      }
    },
    bumen_sure () {
      if (this.$refs.tree_tre.getCheckedNodes().length != 0) {
        activate_customer({
          data: {
            ent_id: this.$ent_id(),
            user_id: this.$jichuxinxi().user_id,
            dept_id: this.$refs.tree_tre.getCheckedNodes()[0].dept_id,
            list: this.xuan_zhong.map(item => {
              return {
                cust_id: item.cust_id
              }
            })
          }
        }).then(res => {
          console.log(res)
          if (res.data.code == 200) {
            this.$message({
              message: '操作成功',
              type: 'success'
            }) 
            this.jichu()
            this.bumen = false
          } else if (res.data.code == 500) {}
        })
      } else {
        this.$message({
          message: '请选择要激活的部门',
          type: 'warning'
        })
      }
    },
    // 点击眼睛
    dakai_danju (i) {
      this.$refs.eye.dianji_dakai(i[0])
    },
    fujian_xiazai (i) {
      this.$func.fujian_xiazai2(i)
    },
    dianji_guidang_hao (index) {
      this.zhanshi_guidang_hao = index
      const item = this.fujian_list[index]
      if (item.con_annex != undefined) {
        item.con_annex.forEach(ite => {
          this.$set(ite, 'zhi', false)
        })
      }
      if (item.inv_annex != undefined) {
        item.inv_annex.forEach(ite => {
          this.$set(ite, 'zhi', false)
        })
      }
      if (item.rec_annex != undefined) {
        item.rec_annex.forEach(ite => {
          this.$set(ite, 'zhi', false)
        })
      }
      if (item.deliver_annex != undefined) {
        item.deliver_annex.forEach(ite => {
          this.$set(ite, 'zhi', false)
        })
      }
    },
    dianji_quanxuan_fujian () {
      this.fujian_quanxuan = !this.fujian_quanxuan
      if (this.fujian_quanxuan) {
        const item = this.fujian_list[this.zhanshi_guidang_hao]
        if (item != undefined) {
          if (item.con_annex != undefined) {
            item.con_annex.forEach(ite => {
              this.$set(ite, 'zhi', true)
            })
          }
          if (item.inv_annex != undefined) {
            item.inv_annex.forEach(ite => {
              this.$set(ite, 'zhi', true)
            })
          }
          if (item.rec_annex != undefined) {
            item.rec_annex.forEach(ite => {
              this.$set(ite, 'zhi', true)
            })
          }
          if (item.deliver_annex != undefined) {
            item.deliver_annex.forEach(ite => {
              this.$set(ite, 'zhi', true)
            })
          }
        }
      } else {
        const item = this.fujian_list[this.zhanshi_guidang_hao]
        if (item != undefined) {
          if (item.con_annex != undefined) {
            item.con_annex.forEach(ite => {
              this.$set(ite, 'zhi', false)
            })
          }
          if (item.inv_annex != undefined) {
            item.inv_annex.forEach(ite => {
              this.$set(ite, 'zhi', false)
            })
          }
          if (item.rec_annex != undefined) {
            item.rec_annex.forEach(ite => {
              this.$set(ite, 'zhi', false)
            })
          }
          if (item.deliver_annex != undefined) {
            item.deliver_annex.forEach(ite => {
              this.$set(ite, 'zhi', false)
            })
          }
        }
      }
    },
    dianji_piliang_xiazi () {
      const list = []
      const item = this.fujian_list[this.zhanshi_guidang_hao]
      if (item != undefined) {
        if (item.con_annex != undefined) {
          item.con_annex.forEach(ite => {
            if (ite.zhi) {
              list.push(ite)
            }
          })
        }
        if (item.inv_annex != undefined) {
          item.inv_annex.forEach(ite => {
            if (ite.zhi) {
              list.push(ite)
            }
          })
        }
        if (item.rec_annex != undefined) {
          item.rec_annex.forEach(ite => {
            if (ite.zhi) {
              list.push(ite)
            }
          })
        }
        if (item.deliver_annex != undefined) {
          item.deliver_annex.forEach(ite => {
            if (ite.zhi) {
              list.push(ite)
            }
          })
        }
        if (list.length != 0) {
          list.forEach(it => {
            this.$func.fujian_xiazai2(it)
          })
        } else {
          this.$message({
            message: '请选择附件进行下载',
            type: 'warning'
          })
        }
      }
    },
    panduan_fujian_quanxuan () {
      const list = []
      const item = this.fujian_list[this.zhanshi_guidang_hao]
      if (item != undefined) {
        console.log(item)
        if (item.con_annex != undefined) {
          const quanbu = []
          item.con_annex.forEach(ite => {
            console.log(ite)
            if (ite.zhi) {
              quanbu.push(ite)
            }
          })
          if (quanbu.length == item.con_annex.length) {
            list.push('1111')
          }
          console.log(quanbu)
        } else {
          list.push('1111')
        }
        if (item.inv_annex != undefined) {
          const quanbu = []
          item.inv_annex.forEach(ite => {
            if (ite.zhi) {
              quanbu.push(ite)
            }
          })
          if (quanbu.length == item.inv_annex.length) {
            list.push('1111')
          }
        } else {
          list.push('1111')
        }
        if (item.rec_annex != undefined) {
          const quanbu = []
          item.rec_annex.forEach(ite => {
            if (ite.zhi) {
              quanbu.push(ite)
            }
          })
          if (quanbu.length == item.rec_annex.length) {
            list.push('1111')
          }
        } else {
          list.push('1111')
        }
        if (item.deliver_annex != undefined) {
          const quanbu = []
          item.deliver_annex.forEach(ite => {
            if (ite.zhi) {
              quanbu.push(ite)
            }
          })
          if (quanbu.length == item.deliver_annex.length) {
            list.push('1111')
          }
        } else {
          list.push('1111')
        }
        setTimeout(() => {
          console.log(list)
          if (list.length == 4 && (item.deliver_annex != undefined || item.con_annex != undefined || item.inv_annex != undefined || item.rec_annex != undefined)) {
            this.fujian_quanxuan = true
          } else {
            this.fujian_quanxuan = false
          }
        }, 0)
      }
    },
    dianji_xiazai_fujian (i) {
      this.$func.fujian_xiazai(i)
    },
    tupian_dianji (img) {
      this.$refs.bigphote.jichu(img)
    },
    // 金额转化
    jine_zhuanhua (num) {
      const a = Number(num)
      if (a >= 100000000) {
        return this.$qianwei((a / 100000000).toFixed(2))
      } else if (a >= 10000 && a < 100000000) {
        return this.$qianwei((a / 10000).toFixed(2))
      } else if (a < 10000) {
        return this.$qianwei(a.toFixed(2))
      }
    },
    // 金额文字转化
    wenzi_jine_zhuanhua (num) {
      const a = Number(num)
      if (a >= 100000000) {
        return '亿'
      } else if (a >= 10000 && a < 100000000) {
        return '万元'
      } else if (a < 10000) {
        return '元'
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
 @import '../../style/salesman.scss'
</style>
